<template>
  <div class="content">
    <vue-whatsapp-widget
      phoneNumber="+524421867547"
      companyName="tulum.party (Gaby)"
      textReply="Typically replies within 10-15 mins (9am - 10pm)"
      companyLogo="https://imgix.cosmicjs.com/4674bea0-3e73-11ec-9828-0f2f00f103e0-telegram-peer-photo-size-1-5166023334515814245-1-0-0.jpg?h=50"
    />
    <div
      class="row"
      :style="{
        background: ` ${this.primaryColor}`,
      }"
    >
      <div class="text-center col-lg-8 col-sm-12">

        <ResponsiveImage :image="astro" alt="tulum party calendar" wl="500" hl="214" clamp></ResponsiveImage>
        <div style="margin-top: -90px">
          <div
            class="header text-center"
          >
            <br />
            <h1 class="tulum-party-title" style="font-size: 3rem">PARTY CALENDAR</h1>

            <p class="font-weight-800" style="font-size: 1rem">tulum.party</p>
            <p style="font-weight: normal; font-size: 10px;margin-top: -5px">
              DO NOT MISS A THING !
            </p>
            <br />


            <div class="card" style="margin: 1px;padding: 10px">

              <p
              >All Events ● Dinners w/ shows ● Daily Updates ● Clubs Activity ● Spontaneous events ●
                <router-link :to="{path: '/google-calendar-party'}">
                  Sync with any calendar app and/or Phone
                </router-link>
                ● Locations ● Start Times ● Event Details & Tickets ●
                <router-link :to="{path: '/google-calendar-party'}">Google Calendar
                  Version
                </router-link>
                Works
                Offline
              </p>
            </div>
            <form @submit.prevent="filterParties">

              <div class="form-group form-control">
                <div
                  class="input-group input-group-lg"
                  :class="searchMode && 'active-input' "
                  style="
                    background-color: #1a1818;
                      max-width: 300px;
                      margin-left: auto;
                      margin-right: auto;
                      left: 0;
                      right: 0;
                      padding: 30px;
                      margin-top: 2px;
                    "
                >

                  <input
                    type="text"
                    class="form-control"
                    aria-describedby="sizing-addon1"
                    name="search_nye"
                    autocomplete="false"
                    v-model.lazy="searchTerm"
                    placeholder="Search"
                  />
                </div>

              </div>
            </form>

          </div>
          <div>
            <div
              class="card col-12 text-center "
              v-if="incomingParties.length === 0"
            >
              <i class="badge-pill " @click="resetSearch($event)"> x {{ searchTerm }}</i>
              <h4 class="badge-dark alt-font" style="margin-top: 0px">
                No event(s) found
              </h4>
              <small>Most events are published one or two weeks prior</small>
            </div>

            <div
              v-if="listView"
              :style="{
                background: `${primaryColor}`,
              }"
              style="margin-top: -55px; padding: 3px; "
            >

              <div class="centered">


                <div>

                  <VDropdown
                    :hideTriggers="['click']"
                    :popperHideTriggers="['click']"
                    :show="!searchMode"


                  >
                    <div style="margin: 40px">

                      <div v-if="!searchMode">
                             <span class="btn btn-link"
                                   style="border: 1px solid #2eafb4;"
                             >{{ this.periodLabel }}

                                          <i class="tim-icons icon-minimal-down"></i>
                                        </span>
                      </div>

                    </div>

                    <template #popper>

                      <div v-if="!searchMode">

                        <p @click="goTo('/today')" class="btn-link"
                        >
                          Today</p>

                        <p @click="getPeriod('this_week', true)" class="btn-link"
                           :class="periodLabel === 'This Week' ? 'active' : '' ">

                          This Week</p>
                        <p @click="getPeriod('next_week', true)" class="btn-link"
                           :class="periodLabel === 'Next Week' ? 'active' : '' ">

                          Next Week</p>

                        <p @click="getPeriod('this_month', true)" class="btn-link"
                           :class="periodLabel === getMonth() ? 'active' : '' ">
                          {{ getMonth() }}</p
                        >
                        <p @click="getPeriod('next_month', true)" class="btn-link"
                           :class="periodLabel === getNextMonth() ? 'active' : '' ">
                          {{ getNextMonth() }}</p
                        >
                        <router-link :to="{name: 'GoogleCalendar'}" target='_blank'>
                          <p class="btn-link btn-danger">Full Calendar</p>
                        </router-link>
                      </div>
                    </template>
                  </VDropdown>
                </div>
              </div>
              <div
                ref="calendar"
                class="col-md-10 offset-md-1 col-sm-10 offset-sm-1"
                style="margin-top: 10px;background: linear-gradient(rgb(73, 73, 73), #ff8d73)"
              >
                <ResponsiveImage :image="hand" alt="upcoming events" wl="500" hl="164" clamp

                                 style="margin-bottom: -40px"
                ></ResponsiveImage>

                <calendar-search-mode v-if="searchMode"
                                      :incoming-parties="incomingParties"
                                      :search-term="searchTerm"
                                      :secondary-color="secondaryColor" />

                <calendar-display-mode :period-parties="periodParties"
                                       v-if="!searchMode"
                                       :period-parties-dates="periodPartiesDates"
                                       :secondary-color="secondaryColor" />
                <div class="card text-center">
                  <br>
                  <img v-lazy="runningAstronaut" height="100" width="100" class="centered">

                  <br>
                  <h4 class="alt-font">Do not miss a thing !</h4>
                  <hr>
                  <p>Checkout our instagram stories for more updates.</p>
                  <br>
                </div>
              </div>

            </div>
          </div>

          <div class="row text-center" style="margin-top: 40px">



            <div
              class="col-12 centered card " style="background-color: black;padding: 20px;margin-top: 30px">


              <SmartMenu :links="links" :logo="this.djs" primary-color="black"
                         top-section-name="PLAN AHEAD"
                         cta-text=" 📲 TALK TO US">
                <div class="row">
                  <div class="col-sm-12 text-center">
                    <img v-lazy="djs+'?w=120&h=120&fit=clamp'" style="border-radius: 120px" height="120px" width="120px"
                         class="centered">
                    <hr>

                    <p>If you need assistance planning or executing your plan, please don't hesitate to contact us.</p>
                    <hr>
                  </div>
                </div>
              </SmartMenu>
            </div>
          </div>
        </div>
      </div>
      <aside class="card col-4 col-lg-4 d-none d-lg-inline">
        <HotThisWeek
          :primary-color="primaryColor"
          align="text-center"
        ></HotThisWeek>

        <SmartMenu
          :links="links"
          :logo="this.runningAstronaut"
          cta-text="RESERVE NOW"
          top-section-name="TALK TO US"
        >
          <p>

            <strong>tulum.party</strong><br> The Single Contact for All Clubs & Events<br>
          </p>
          <br>

        </SmartMenu>
      </aside>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import SmartMenu from '@/components/SmartMenu/SmartMenu';
import HotThisWeek from '@/pages/TulumDjSchedule/HotThisWeek';
import { eventDateUtils } from '@/common/eventDateUtils';
import ResponsiveImage from '@/components/HeadImage/ResponsiveImage';
import CalendarSearchMode from '@/pages/PartyCalendar/CalendarSearchMode';
import CalendarDisplayMode from '@/pages/PartyCalendar/CalendarDisplayMode';

const VueWhatsappWidget = () =>
  import(
    /* webpackChunkName: "vue-whatsapp-widget" */ '../../components/WhatsAppWidget/Widget'
    );

export default {
  name: 'PartyCalendar',
  mixins: [eventDateUtils],
  data: () => {
    return {
      showPalms: false,
      form: '',
      searchTerm: '',
      searchMode: false,
      primaryColor: '#1f1f1f',
      secondaryColor: '#3c3c3c',
      thirdColor: '#ff8d73',
      compact: false,
      showCalendarTab: false,
      showEventsTab: true,
      period: 'week',
      periodLabel: 'this_week',
      logo:
        'https://imgix.cosmicjs.com/3acdf350-9b87-11ee-b34c-99d1d89e42eb-tp-logo-40pt.png',

      astro: 'https://imgix.cosmicjs.com/fdb719e0-e8fd-11ef-b333-e101bec29f3d-e82abebd3cb5882d4423c7666a57660b.webp',
      djs:
        'https://imgix.cosmicjs.com/717f22a0-d081-11ee-9ce5-59949019255e-tulum-party-green.jpg',
      periodPartiesDates: [],
      blueEyeImage:
        'https://imgix.cosmicjs.com/91cad540-ac13-11ef-bee4-3bb1d3c55332-diver-rayado.jpg',
      listView: true,
      periodParties: [],
      hand: 'https://imgix.cosmicjs.com/ba0e6740-047b-11f0-9c94-c1d9b01cc071-a97cd11eda3042f0bcc52c26641b8240-1.png',
      runningAstronaut: 'https://imgix.cosmicjs.com/c6bf6620-f345-11ef-b4ad-8b78ecd420c2-running-orange-astro.png',
      mainImage:
        'https://imgix.cosmicjs.com/ba0e6740-047b-11f0-9c94-c1d9b01cc071-a97cd11eda3042f0bcc52c26641b8240-1.png',
      calImage:
        'https://imgix.cosmicjs.com/00c12a20-7d7c-11ee-9baf-2960bf73c40e-image.png',
      gCalImg:
        'https://imgix.cosmicjs.com/cdf97730-803e-11ee-9ebe-eb22b0517bf0-image.png',
      bgPalm:
        'https://imgix.cosmicjs.com/717f22a0-d081-11ee-9ce5-59949019255e-tulum-party-green.jpg',
      birdsImg:
        'https://imgix.cosmicjs.com/63c3dfe0-9b0f-11ee-b34c-99d1d89e42eb-image.png'
    };
  },
  metaInfo() {
    return {
      title: `Tulum Parties, Events & Shows Calendar.`,
      link: [
        {
          rel: 'canonical',
          href: 'https://www.tulum.party/tulum-party-mexico-event-calendar'
        }
      ],
      meta: [
        {
          name: 'description',
          content: `Information, Location, Dates and Times for the Top Parties, Events, Shows & Dinners in Tulum & The Riviera Maya`
        },
        {
          name: 'keywords',
          content: `Tulum Party Calendar. Upcoming Events @ Tulum Mexico, events, calendar, clubs, party agenda, dj agenda, dinner, shows`
        },
        {
          property: 'og:title',
          content: `Tulum Parties, Events & Shows Calendar.`
        },
        {
          property: 'og:url',
          href: 'https://www.tulum.party/tulum-party-mexico-event-calendar'
        },
        {
          property: 'og:description',
          content: `Information, Location, Dates and Times for the Top Parties, Events, Shows & Dinners in Tulum & The Riviera Maya`
        },
        { property: 'og:type', content: 'website' }
      ]
    };
  },
  components: {
    CalendarDisplayMode,
    CalendarSearchMode,
    ResponsiveImage,
    HotThisWeek,
    SmartMenu,
    VueWhatsappWidget
  },
  computed: {
    incomingParties: {
      get() {
        return this.filterParties() || this.$store.state.calendar;
      }
    },
    links: {
      get() {
        return this.$store.state.links || [];
      }
    },
    photos: {
      get() {
        return this.incomingParties.map((x) => x.metadata.main_image.imgix_url);
      }
    },
    photoCaptions: {
      get() {
        return this.incomingParties.map(
          (x) => x.title + ' - ' + x.metadata.date
        );
      }
    },
    thisMonth: {
      get() {
        return moment().format('MMMM');
      }
    },
    nextMonth: {
      get() {
        return moment().add('month', 1).format('MMMM');
      }
    }
  },
  mounted: function() {
    moment.updateLocale('en', {
      week: {
        dow: 1 // Monday is the first day of the week.
      }
    });
    if (this.$route.query.compact) {
      this.compact = true;
    }
    this.getPeriod('this_week', true);
    this.$store.dispatch('getLinks', {
      slug: 'tulum-party-mexico-event-calendar'
    });
    this.$store.dispatch('getAllParties').then((_x) => {
      this.getPeriod(this.$route.query.period || 'this_week', true);
    });
    setTimeout(() => {
      this.showPalms = true;
    }, 3000);
  },
  methods: {
    resetSearch(event) {
      // alert(111);
      console.log(this.searchTerm);
      event.target.reset();
      this.searchTerm = '';
      this.searchMode = false;
    },
    goTo: function goTo(page) {
      this.$router.push({ path: page, replace: true });
    },
    onQuickReserveClick() {
      window.ga(
        'send',
        'event',
        'reservations',
        'PartySupport',
        'Calendar',
        20
      );
      this.$rollbar.info('PartySupport On Calendar');
    },
    filterParties: function filterParties() {
      this.searchMode = false;

      if (this.searchTerm) {
        if (this.searchTerm.length > 2) {
          this.$rollbar.info(' SEARCH', { keyword: this.searchTerm });
          this.searchMode = true;
        }
        // this.notifyChange('Showing Results For: ' + this.searchTerm);
        // this.changeView('compact');
        return [...this.$store.state.calendar].filter((item) => {
          // let lineup = item.metadata.lineup || '';
          return this.searchTerm
            ? item.slug.match(this.searchTerm.toLowerCase()) ||
            item.metadata.date.match(this.searchTerm.toLowerCase()) ||
            item.title.toLowerCase().match(this.searchTerm.toLowerCase())

            : true;
        });
      }
    },
    getPeriod: function getPeriod(period, silent) {
      this.searchMode = false;
      let label = '';
      if (period === 'today') {
        label = 'Today';
        this.getThisPeriod('day', label);
      }
      if (period === 'tomorrow') {
        label = 'Tomorrow';
        this.getNextPeriod('day', label);
      }
      if (period === 'this_week') {
        label = 'This Week';
        this.getThisPeriod('week', label);
      }
      if (period === 'next_week') {
        label = 'Next Week';
        this.getNextPeriod('week', label);
      }

      if (period === 'this_month') {
        label = this.thisMonth;
        this.getThisPeriod('month', label);
      }
      if (period === 'next_month') {
        label = this.nextMonth;
        this.getNextPeriod('month', label);
      }
      if (period === 'all') {
        label = 'All';
        this.$route.path;
        // this.getThisPeriod('year', label);
      }
      if (!silent) {
        this.notifyChange('Showing events for ' + label);
        // this.$rollbar.info('Calendar: ' + label);
      }

    },

    getThisPeriod: function getThisPeriod(unitOfTime, label) {
      let parties = this.incomingParties;

      this.period = unitOfTime;
      this.periodLabel = label;
      this.periodParties = [...parties].filter((x) =>
        this.isIncomingParty(unitOfTime, x)
      );
      let _periodDates = {};
      this.periodPartiesDates = [];
      this.periodParties.forEach((y) => {
        if (_periodDates[y.metadata.date]) {
          this.periodPartiesDates.push(null);
        } else {
          _periodDates[y.metadata.date] = true;
          this.periodPartiesDates.push(y.metadata.date);
        }
      });
    },
    getNextPeriod: function getNextPeriod(unitOfTime, label) {
      let parties = this.incomingParties;
      this.period = unitOfTime;
      this.periodLabel = label;

      this.periodParties = [...parties].filter((x) =>
        this.isIncomingNext(unitOfTime, x)
      );
      let _periodDates = {};
      this.periodPartiesDates = [];
      this.periodParties.forEach((y) => {
        if (_periodDates[y.metadata.date]) {
          this.periodPartiesDates.push(null);
        } else {
          _periodDates[y.metadata.date] = true;
          this.periodPartiesDates.push(y.metadata.date);
        }
      });
      this.period = unitOfTime;
    },
    addHashToLocation(params, compact) {
      let path = this.$route.path + '?period=' + encodeURIComponent(params);
      if (compact) {
        path += '&compact=true';
      }
      history.pushState({}, null, path);
    },
    notifyChange: function notifyChange(change) {
      this.$notify({
        type: 'primary',
        title: 'Calendar',
        message: `${change}`,
        icon: 'tim-icons icon-bell-55'
      });
    },

    isIncomingParty: function isIncomingParty(unitOfTime, x) {
      return (
        moment()
          .startOf(unitOfTime)
          .isSame(x.metadata.date + 'T' + x.metadata.start_time, unitOfTime) &&
        (
          !x.metadata.magicEvent ||
          (x.metadata.magicEvent && x.metadata.show_in_calendar))
      );
    },

    isIncomingNext: function isIncomingNext(unitOfTime, x) {
      return (
        moment()
          .add(1, unitOfTime)
          .startOf(unitOfTime)
          .isSame(x.metadata.date + ' ' + x.metadata.start_time, unitOfTime) &&
        (
          !x.metadata.magicEvent ||
          (x.metadata.magicEvent && x.metadata.show_in_calendar))
      );
    },
    googleCalendar() {
      window.ga('send', 'event', 'Event', 'Calendar', 'Google Calendar', 1);
      this.$rollbar.info(`Google Calendar Open`);
    }
  }
};
</script>
