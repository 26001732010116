<template>
  <div >
    <div v-for="(event, idx) in periodParties"
         class="card" style="margin-bottom: 2px;">
      <p class="text-left section-header " v-if="idx === 0">
        {{ getMonth(event.metadata.date) }}, 2025</p>
      <div class="text-left section-header ">

        <div v-if="periodPartiesDates[idx] " class="card"

             style="padding: 10px;"
        >
          <div class="flex-container">
            <div class="flex-container-col">

              <p class="alt-font" style="font-weight: bold;
              font-size: 0.7rem;padding-bottom: 2px; margin-bottom: -10px;  color: rgba(255,141,114,0.77) !important;text-transform: uppercase">{{ getDayOfWeek(event.metadata.date + 'T00:00') }}</p>

              <h3
                  class="text-left"
                  style="
                      text-transform: capitalize;
                      font-weight: bold;
                      margin-bottom: 10px;
                      font-size: 2.1rem;
                    "
              >
                {{

                getDayNumeric(
                event.metadata.date + 'T' + event.metadata.start_time
                ) + ' ' +

                getMonth(
                  event.metadata.date + 'T' + event.metadata.start_time
                )
                }}

              </h3>
            </div>
          </div>


        </div>
        <div class=" animation-on-hover " style="padding:  20px 5px 20px 0;

"
             :style="{backgroundColor: secondaryColor}">
          <router-link
              :to="
                      event.metadata.magicEvent
                        ? { path: event.metadata.link + '?event='+event.slug}
                        : { name: 'Event', params: { slug: event.slug } }
                    "
          >
            <div class="flex-container">

              <div class="flex-container-col">

                <!--                          <img v-lazy="event.metadata.main_image.imgix_url" height="70px" width="70px">-->
<!--                <p >-->
<!--                  <strong style="font-size: 1rem"> {{-->
<!--                    getMonth(-->
<!--                    event.metadata.date + 'T' + event.metadata.start_time-->
<!--                    ) + ', ' +-->
<!--                    getDayNumeric(-->
<!--                    event.metadata.date + 'T' + event.metadata.start_time-->
<!--                    )-->
<!--                    }}</strong>-->
<!--                </p>-->
                <p>
                  <strong style="font-size: 1.6rem">{{
                    event.metadata.start_time
                    }}</strong>
                </p>
              </div>

              <div class="flex-container-col">
                <p class="font-weight-900" style="color: mediumaquamarine !important;">{{ event.title.split('@')[0]
                  }}</p>

                <p style="">
                  {{ event.title.split('@')[1] }}

                </p>

              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { eventDateUtils } from '@/common/eventDateUtils';

export default {
  name: 'calendar-display-mode',
  mixins: [eventDateUtils],
  props: {
    periodParties: {},
    periodPartiesDates: {},
    secondaryColor: {}
  }
};
</script>
