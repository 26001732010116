<template>
  <div >
    <div v-for="(event, idx) in incomingParties"
         class="card" style="margin-bottom: 2px">


      <div v-if="idx === 0" class="section-header text-left"><i class="badge-pill " @click="resetSearch"> x {{
        searchTerm }}</i> |
        Results
        {{ incomingParties.length }}
      </div>

      <div class=" animation-on-hover " style="padding:  20px 5px 20px 0;"
           :style="{backgroundColor: secondaryColor}">

        <router-link
            :to="
                      event.metadata.magicEvent
                        ? { name: 'MiniEvent', params: { slug: event.slug } }
                        : { name: 'Event', params: { slug: event.slug } }
                    "
        >
          <div class="flex-container">
            <div class="flex-container-col">
              <p >
                <small>{{ getDayOfWeek(event.metadata.date + 'T00:00') }}</small>

                <br>
                <strong style="font-size: 1rem"> {{
                  getMonth(
                  event.metadata.date + 'T' + event.metadata.start_time
                  ) + ', ' +
                  getDayNumeric(
                  event.metadata.date + 'T' + event.metadata.start_time
                  )
                  }}</strong>
              </p>
              <p>
                <strong style="font-size: 1.6rem">{{
                  event.metadata.start_time
                  }}</strong>
              </p>
            </div>
            <div class="flex-container-col">
              <p class="font-weight-900" style="color: mediumaquamarine !important;">{{ event.title.split('@')[0] }}</p>
              <p style="">
                {{ event.title.split('@')[1] }}

              </p>

            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { eventDateUtils } from '@/common/eventDateUtils';

export default {
  name: 'calendar-search-mode',
  mixins: [eventDateUtils],
  props: {
    incomingParties: {},
    resetSearch: {},
    searchMode: {},
    searchTerm: {},
    secondaryColor: {}
  }
};
</script>
